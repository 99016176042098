function ButtonClose() {
    return {
        render: render
    };
}
var render = function () {
    var buttonContainer = document.createElement('div');
    var buttonClose = document.createElement('div');
    buttonClose.setAttribute('role', 'button');
    buttonClose.id = 'deUnaCheckoutWidget__close_button';
    buttonClose.innerHTML =
        '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#a)"><path d="M25.96 2.751 23.81.6l-8.53 8.529L6.751.6l-2.15 2.151 8.528 8.529L4.6 19.809l2.151 2.151 8.53-8.529 8.528 8.529 2.151-2.151-8.529-8.529 8.53-8.529Z" fill="#F50"/><path d="m17.078 11.633 8.175 8.176-1.444 1.444-8.175-8.175-.354-.354-.353.354-8.176 8.175-1.444-1.444 8.175-8.176.354-.353-.354-.354-8.175-8.175 1.444-1.444 8.175 8.175.354.354.354-.354 8.175-8.175 1.444 1.444-8.175 8.175-.354.354.354.354Z" stroke="#000"/></g><defs><filter id="a" x=".6" y=".6" width="29.36" height="29.36" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4324_18244"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_4324_18244" result="shape"/></filter></defs></svg>';
    buttonClose.classList.add('d-Una__checkout__close');
    buttonContainer.appendChild(buttonClose);
    buttonContainer.classList.add('d-Una__checkout__button__container');
    return buttonContainer;
};
export default ButtonClose();
