var LogTime = /** @class */ (function () {
    function LogTime(name) {
        this.name = name;
    }
    LogTime.prototype.start = function () {
        this.startTime = new Date();
        return this;
    };
    LogTime.prototype.end = function () {
        this.endTime = new Date();
        return this;
    };
    LogTime.prototype.getDuration = function () {
        var durationInSeconds = (this.endTime.getTime() - this.startTime.getTime()) / 1000;
        return {
            name: this.name,
            start: this.startTime,
            end: this.endTime,
            duration: durationInSeconds
        };
    };
    return LogTime;
}());
export { LogTime };
