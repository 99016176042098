var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c;
import buildZoid from './components/zoid';
import Container from './components/container';
import ButtonClose from './components/button';
import Modal from './components/modal';
import './styles/main.sass';
import { initGTM } from './utils/initGTM';
import { uuid } from './utils/uuid';
import { Env, urls } from './envs';
import { addViewPort } from './utils/addViewport';
import { getUrl } from './utils/getUrl';
import { getCDL } from './utils/getCDL';
import getShouldOpenCheckout from './utils/getShouldOpenCheckout';
import ErrorMessages from './enum/errorMessages';
import { LogTime } from './utils/LogTime';
import { loadCdlScript } from './utils/loadCdlScript';
addViewPort();
var onScriptLoad = function (_a) {
    var scriptApiKey = _a.scriptApiKey, scriptEnv = _a.scriptEnv, scriptOnLoad = _a.scriptOnLoad;
    return __awaiter(void 0, void 0, void 0, function () {
        var env, _b, callbackFn;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!scriptEnv) {
                        return [2 /*return*/];
                    }
                    env = scriptEnv || Env.Production;
                    return [4 /*yield*/, loadCdlScript(env)];
                case 1:
                    _c.sent();
                    if (!scriptApiKey) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getCDL(scriptApiKey, env)];
                case 2:
                    _c.sent();
                    _b = window;
                    return [4 /*yield*/, getShouldOpenCheckout({
                            apiKey: scriptApiKey,
                            env: env,
                            orderToken: ''
                        })];
                case 3:
                    _b.deunaShouldOpenCheckout = _c.sent();
                    if (scriptOnLoad in window) {
                        callbackFn = window[scriptOnLoad];
                        if (typeof callbackFn === 'function') {
                            callbackFn();
                        }
                    }
                    return [2 /*return*/];
            }
        });
    });
};
var scriptEnv = (_a = document === null || document === void 0 ? void 0 : document.currentScript) === null || _a === void 0 ? void 0 : _a.getAttribute('env');
var scriptApiKey = (_b = document === null || document === void 0 ? void 0 : document.currentScript) === null || _b === void 0 ? void 0 : _b.getAttribute('api-key');
var scriptOnLoad = (_c = document === null || document === void 0 ? void 0 : document.currentScript) === null || _c === void 0 ? void 0 : _c.getAttribute('onload');
document.addEventListener('DOMContentLoaded', function () { return onScriptLoad({ scriptApiKey: scriptApiKey, scriptEnv: scriptEnv, scriptOnLoad: scriptOnLoad }); }, false);
onScriptLoad({ scriptApiKey: scriptApiKey, scriptEnv: scriptEnv, scriptOnLoad: scriptOnLoad });
var configureLog = new LogTime('configure');
var totalTime = new LogTime('totalTime');
var urlLogTime = new LogTime('getUrl');
var renderModal = new LogTime('renderModal');
var fraudCredentials = new LogTime('fraudCredentials');
var globalZoidInstanceDEUNA = null;
var status = 'none';
var interval = 0;
function DeunaCheckout() {
    return {
        version: process.env.COMMIT_HASH,
        config: null,
        zoidInstance: null,
        container: null,
        ready: false,
        configure: function (params) {
            var _a, _b, _c, _d;
            return __awaiter(this, void 0, void 0, function () {
                var url;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            totalTime.start();
                            configureLog.start();
                            this.config = params;
                            urlLogTime.start();
                            return [4 /*yield*/, getUrl({
                                    env: params.env,
                                    devMode: params.devMode,
                                    url: params.url,
                                    apiKey: this.config.apiKey,
                                    orderToken: params.orderToken,
                                    experimental: params.experimental
                                })];
                        case 1:
                            url = _e.sent();
                            urlLogTime.end();
                            this.ready = false;
                            this.config.url = url;
                            if (window.globalZoidInstanceDEUNA) {
                                this.zoidInstance = window.globalZoidInstanceDEUNA;
                            }
                            else {
                                window.globalZoidInstanceDEUNA = buildZoid({ url: this.config.url });
                                this.zoidInstance = window.globalZoidInstanceDEUNA;
                                if (((_b = (_a = this.config.analytics) === null || _a === void 0 ? void 0 : _a.googleTagManager) === null || _b === void 0 ? void 0 : _b.keyId) &&
                                    !window.dataLayer) {
                                    initGTM((_d = (_c = this.config.analytics) === null || _c === void 0 ? void 0 : _c.googleTagManager) === null || _d === void 0 ? void 0 : _d.keyId);
                                }
                            }
                            configureLog.end();
                            return [2 /*return*/];
                    }
                });
            });
        },
        renderModal: function () {
            var _a, _b, _c, _d, _e;
            return __awaiter(this, void 0, void 0, function () {
                var appendToDom, toggleScroll, containerDom, modal, btnClose, _f, cdlDeviceId, sessionId, closeWidget, properties;
                var _this = this;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            renderModal.start();
                            appendToDom = function (parent, child) {
                                if (document.getElementById(child.id) !== null)
                                    return;
                                parent.appendChild(child);
                            };
                            toggleScroll = function (visible) {
                                var body = document.getElementsByTagName('body')[0];
                                if (visible) {
                                    return body.classList.add('dUna__checkout__enable_overflow');
                                }
                                body.classList.remove('dUna__checkout__enable_overflow');
                                body.classList.add('dUna__checkout__disable_overflow');
                            };
                            this.container = Container();
                            containerDom = this.container.render();
                            modal = Modal.render((_a = this.config.checkoutConfig) === null || _a === void 0 ? void 0 : _a.targetSelector);
                            btnClose = ButtonClose.render();
                            toggleScroll(false);
                            if ((_b = this.config.checkoutConfig) === null || _b === void 0 ? void 0 : _b.targetSelector) {
                                appendToDom(document.body, modal);
                            }
                            else {
                                appendToDom(document.body, containerDom);
                                appendToDom(modal, btnClose);
                                appendToDom(containerDom, modal);
                            }
                            fraudCredentials.start();
                            return [4 /*yield*/, getCDL(this.config.apiKey, this.config.env)];
                        case 1:
                            _f = _g.sent(), cdlDeviceId = _f.deviceId, sessionId = _f.sessionId;
                            fraudCredentials.end();
                            closeWidget = function () {
                                _this.container.visible(false);
                                var container = document.querySelectorAll('.d-Una__checkout__container')[0];
                                toggleScroll(true);
                                if (container) {
                                    container.remove();
                                }
                            };
                            renderModal.end();
                            totalTime.end();
                            properties = __assign(__assign({}, this.config), { analytics: __assign(__assign({}, this.config.analytics), { sessionId: ((_c = this.config.analytics) === null || _c === void 0 ? void 0 : _c.sessionId) || uuid() }), url: this.config.url || urls.production || '', getMyLocation: function (cb) {
                                    if (navigator.geolocation) {
                                        navigator.geolocation.getCurrentPosition(function (position) {
                                            var coords = {
                                                lat: position.coords.latitude,
                                                lng: position.coords.longitude
                                            };
                                            cb({ coords: coords, error: null });
                                        }, function (error) {
                                            if (error.message == ErrorMessages.geolocalizationError) {
                                                cb({ coords: null, error: error });
                                            }
                                        });
                                    }
                                }, copyToClipboard: function (text) {
                                    navigator.clipboard.writeText(text);
                                }, gtmEvents: {
                                    push: function (data) {
                                        var _a;
                                        // use client current dataLayer
                                        if (window.dataLayer) {
                                            window.dataLayer.push(data);
                                            return;
                                        }
                                        //@ts-ignore
                                        (_a = window === null || window === void 0 ? void 0 : window.dunaDataLayer) === null || _a === void 0 ? void 0 : _a.push(data);
                                    }
                                }, toggleCloseButtonVisibility: function (visibility) {
                                    var closeButton = document.getElementById('deUnaCheckoutWidget__close_button');
                                    closeButton.style.visibility = visibility;
                                }, cdl: {
                                    sessionId: sessionId,
                                    deviceId: cdlDeviceId
                                }, getMercadoPagoDeviceId: function () {
                                    try {
                                        return window.mercadoPagopDeviceId;
                                    }
                                    catch (e) {
                                        return null;
                                    }
                                }, closeWidget: closeWidget, metrics: {
                                    configure: configureLog.getDuration(),
                                    getUrl: urlLogTime.getDuration(),
                                    renderModal: renderModal.getDuration(),
                                    totalTime: totalTime.getDuration(),
                                    fraudCredentials: fraudCredentials.getDuration()
                                } });
                            console.log(properties.cdl);
                            // the only two elements that must contain button and the iframe
                            if (((_e = (_d = document.getElementById(modal.id)) === null || _d === void 0 ? void 0 : _d.childNodes) === null || _e === void 0 ? void 0 : _e.length) < 2) {
                                this.zoidInstance(properties).render("#".concat(modal.id));
                            }
                            this.ready = true;
                            btnClose.addEventListener('click', function () {
                                closeWidget();
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        shouldOpenCheckout: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (typeof window.deunaShouldOpenCheckout === 'boolean') {
                        return [2 /*return*/, window.deunaShouldOpenCheckout];
                    }
                    if (params) {
                        this.config = params;
                    }
                    return [2 /*return*/, getShouldOpenCheckout(this.config)];
                });
            });
        },
        show: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.renderModal()];
                        case 1:
                            _a.sent();
                            this.container.visible(true);
                            return [2 /*return*/];
                    }
                });
            });
        }
    };
}
export default DeunaCheckout;
