var CustomStorage = /** @class */ (function () {
    function CustomStorage(type) {
        this.isAvailable = false;
        this.isAvailable = this.isStorageAvailable(type);
    }
    CustomStorage.prototype.isStorageAvailable = function (type) {
        try {
            this.storageType = window[type];
            this.storageType.key(0);
            return true;
        }
        catch (error) {
            return false;
        }
    };
    CustomStorage.prototype.getStorage = function () {
        if (this.isAvailable) {
            return this.storageType;
        }
        return null;
    };
    CustomStorage.prototype.getItem = function (key) {
        if (this.isAvailable) {
            return this.storageType.getItem(key);
        }
        return null;
    };
    CustomStorage.prototype.removeItem = function (key) {
        if (this.isAvailable) {
            this.storageType.removeItem(key);
        }
    };
    CustomStorage.prototype.setItem = function (key, value) {
        if (this.isAvailable) {
            this.storageType.setItem(key, value);
        }
    };
    return CustomStorage;
}());
export default CustomStorage;
