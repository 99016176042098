var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiGWURL } from '../envs';
import { fetchData } from '../utils/fetch';
import { getDataSession, purgeSession, saveDataSession } from './sessionStorageHandler';
import { dateAdd } from './dateAdd';
import { getCDL } from './getCDL';
var getStatus = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var apiGW, apiKey, deviceId, sessionId, userEmail, userPhone, isDateExpired, checkoutStatus, url, _a, data, error, date;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                apiGW = params.apiGW, apiKey = params.apiKey, deviceId = params.deviceId, sessionId = params.sessionId, userEmail = params.userEmail, userPhone = params.userPhone;
                isDateExpired = function (expireDate) {
                    if (!expireDate) {
                        purgeSession('checkout-status');
                        return true;
                    }
                    var date = new Date(expireDate);
                    var today = new Date();
                    if (date < today) {
                        purgeSession('checkout-status');
                        return true;
                    }
                    return false;
                };
                checkoutStatus = getDataSession('checkout-status');
                if (checkoutStatus && !isDateExpired(checkoutStatus.expire_date)) {
                    return [2 /*return*/, checkoutStatus.active];
                }
                url = new URL("".concat(apiGW, "checkout/status"));
                if (userEmail)
                    url.searchParams.append('user_email', userEmail);
                if (userPhone)
                    url.searchParams.append('user_phone', userPhone);
                return [4 /*yield*/, fetchData("".concat(url.href), {
                        headers: {
                            'X-API-KEY': apiKey,
                            'X-Device-Id': deviceId,
                            'X-Session-ID': sessionId
                        },
                        timeout: 2000
                    })];
            case 1:
                _a = _b.sent(), data = _a.data, error = _a.error;
                if (!error) {
                    date = dateAdd(new Date(), 'hour', 6);
                    saveDataSession('checkout-status', __assign(__assign({}, data), { expire_date: date.toJSON() }));
                    return [2 /*return*/, data.active];
                }
                return [2 /*return*/, false];
        }
    });
}); };
var getShouldOpenCheckout = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var config, urlParams, forceOpen, env, apiGW, apiKey, _a, deviceId, sessionId, _b, user, userError, isActive, isUserCDL;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                config = params;
                urlParams = new URLSearchParams(window.location.search);
                forceOpen = urlParams.get('deuna_checkout');
                if (forceOpen === 'true') {
                    return [2 /*return*/, true];
                }
                if (forceOpen === 'false') {
                    return [2 /*return*/, false];
                }
                env = (config === null || config === void 0 ? void 0 : config.env) || 'production';
                apiGW = apiGWURL[env];
                apiKey = config.apiKey;
                return [4 /*yield*/, getCDL(config.apiKey, config.env)];
            case 1:
                _a = _c.sent(), deviceId = _a.deviceId, sessionId = _a.sessionId;
                return [4 /*yield*/, fetchData("".concat(apiGW, "users/login/device-fingerprint"), {
                        method: 'POST',
                        headers: {
                            'X-API-KEY': apiKey,
                            'X-Device-Id': deviceId,
                            'X-Session-ID': sessionId
                        }
                    })];
            case 2:
                _b = _c.sent(), user = _b.data, userError = _b.error;
                return [4 /*yield*/, getStatus({
                        apiGW: apiGW,
                        apiKey: apiKey,
                        deviceId: deviceId,
                        sessionId: sessionId,
                        userEmail: config.userEmail,
                        userPhone: config.userPhone
                    })];
            case 3:
                isActive = _c.sent();
                isUserCDL = !userError && (user === null || user === void 0 ? void 0 : user.refreshToken);
                if (isUserCDL && isActive) {
                    return [2 /*return*/, true];
                }
                return [2 /*return*/, isActive];
        }
    });
}); };
export default getShouldOpenCheckout;
