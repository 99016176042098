import { SplitFactory } from '@splitsoftware/splitio';
import { Env, splitAuthorizationKeys } from '../envs';
var Factory = function (_a) {
    var key = _a.key, authorizationKey = _a.authorizationKey, _b = _a.readyTimeout, readyTimeout = _b === void 0 ? 1.5 : _b;
    return SplitFactory({
        core: {
            key: key,
            authorizationKey: authorizationKey
        },
        startup: {
            readyTimeout: readyTimeout
        }
    });
};
export var SplitIOClient = function (_a) {
    var _b = _a.env, env = _b === void 0 ? Env.Production : _b, key = _a.key, readyTimeout = _a.readyTimeout;
    var authorizationKey = splitAuthorizationKeys[env];
    var factory = Factory({ key: key, authorizationKey: authorizationKey, readyTimeout: readyTimeout });
    return factory.client();
};
