import logo from '../svg/logo';
import backToShoppinCart from '../svg/backToShoppingCart';
import spinner from '../svg/spinner';
import { maxMobileWidth } from '../constants';
var addStyle = function (elm, style) {
    for (var _i = 0, _a = Object.entries(style); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], val = _b[1];
        elm.style[key] = val;
    }
};
var closeWidget = function () {
    var container = document.querySelectorAll('.d-Una__checkout__container')[0];
    if (container) {
        container.remove();
    }
};
export var loader = function (doc) {
    var html = doc.createElement('html');
    var div = doc.createElement('div');
    var deUnaLogoContainer = doc.createElement('div');
    var container = doc.createElement('div');
    var logoContainer = doc.createElement('div');
    var spinnner = doc.createElement('div');
    var backToShoppingCart = doc.createElement('div');
    backToShoppingCart.setAttribute('role', 'button');
    var isSmallWidth = window.matchMedia("(max-width: ".concat(maxMobileWidth, ")"));
    if (isSmallWidth.matches) {
        backToShoppingCart.style.display = 'block';
    }
    else {
        backToShoppingCart.style.display = 'none';
    }
    backToShoppingCart.style.cursor = 'pointer';
    addStyle(div, {
        height: '100%',
        width: '100%',
        backgroundColor: '#FAFAFA',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: "repeat(1, 1fr)"
    });
    addStyle(deUnaLogoContainer, {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '80px'
    });
    addStyle(container, {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    });
    spinnner.innerHTML = spinner;
    spinnner.style.marginRight = '10px';
    logoContainer.innerHTML = logo;
    backToShoppingCart.innerHTML = backToShoppinCart;
    deUnaLogoContainer.appendChild(spinnner);
    deUnaLogoContainer.appendChild(logoContainer);
    container.appendChild(deUnaLogoContainer);
    container.appendChild(backToShoppingCart);
    div.appendChild(container);
    backToShoppingCart.addEventListener('click', function () {
        closeWidget();
    });
    html.appendChild(div);
    return html;
};
