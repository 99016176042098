export var Env;
(function (Env) {
    Env["Production"] = "production";
    Env["Staging"] = "staging";
    Env["Develop"] = "develop";
})(Env || (Env = {}));
export var apiGWURL = {
    production: 'https://apigw.getduna.com/',
    staging: 'https://api.stg.deuna.io/',
    develop: 'https://api.dev.deuna.io/'
};
export var urls = {
    production: 'https://checkout-ux.deuna.com/',
    staging: 'https://checkout.stg.deuna.io/',
    develop: 'https://checkout.dev.deuna.io/'
};
export var cdlUrls = {
    production: 'https://cdn.getduna.com/cdl/index.js',
    staging: 'https://cdn.stg.deuna.io/cdl/index.js',
    develop: 'https://cdn.dev.deuna.io/cdl/index.js'
};
export var versionUrl = {
    production: 'https://checkout-version.deuna.com/',
    staging: 'https://checkout-version.stg.deuna.io/',
    develop: 'https://checkout-version.dev.deuna.io/'
};
export var modularCheckoutUrls = {
    production: 'https://checkout-qsr.deuna.com/',
    staging: 'https://checkout10-kfc.stg.deuna.io',
    develop: 'https://checkout10-kfc.dev.deuna.io'
};
export var splitAuthorizationKeys = {
    production: 'gtrl5t9pbgj0eopn40ve3i09rdjop9h3n41g',
    staging: '4nepmb3l2u0rl0p9j0cmtqbmnvi8ic8obuiu',
    develop: '4nepmb3l2u0rl0p9j0cmtqbmnvi8ic8obuiu'
};
