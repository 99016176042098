export var addViewPort = function () {
    var content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0';
    var viewPortTag = document.querySelector("meta[name='viewport']");
    if (viewPortTag) {
        viewPortTag.setAttribute('content', content);
    }
    else {
        var newViewPort = document.createElement('meta');
        newViewPort.name = 'viewport';
        newViewPort.content = content;
        document.getElementsByTagName('head')[0].appendChild(newViewPort);
    }
};
