function Container() {
    return {
        container: null,
        render: function () {
            var container = document.createElement('div');
            container.classList.add('d-Una__checkout__container');
            container.id = 'dUnaCheckoutWidget';
            this.container = container;
            return container;
        },
        visible: function (value) {
            if (value) {
                this.container.classList.add('d-Una__checkout__container--visible');
            }
            else {
                this.container.classList.remove('d-Una__checkout__container--visible');
            }
        }
    };
}
export default Container;
