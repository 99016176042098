// @ts-ignore
import * as zoid from 'zoid/dist/zoid';
import { loader } from './loader';
function initZoid(data) {
    var widget = zoid.create({
        tag: 'd-una-checkout',
        url: function (a) {
            var url = new URL(data === null || data === void 0 ? void 0 : data.url);
            var urlParams = new URLSearchParams(url.search);
            urlParams.set('xpropsb64', btoa(JSON.stringify(a.props)));
            return "".concat(url.href, "?").concat(urlParams.toString());
        },
        prerenderTemplate: function containerTemplate(_a) {
            var doc = _a.doc;
            return loader(doc);
        },
        dimensions: {
            width: '100%',
            height: '100%'
        },
        attributes: {
            iframe: {
                allow: "geolocation;clipboard-write"
            }
        }
    });
    return widget;
}
export default initZoid;
