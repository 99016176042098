import CustomStorage from './CustomStorage';
var customStorage = new CustomStorage('sessionStorage');
export function saveDataSession(name, data) {
    customStorage.setItem(name, JSON.stringify(data));
}
export function getDataSession(name) {
    var data = customStorage.getItem(name);
    if (!data)
        return null;
    try {
        return JSON.parse(data);
    }
    catch (error) {
        return null;
    }
}
export function purgeSession(name) {
    var data = customStorage.removeItem(name);
}
