function Modal() {
    return {
        render: render,
        removeIframe: removeIframe
    };
}
var render = function (targetSelector) {
    var modal;
    if (targetSelector) {
        modal = document.querySelector(targetSelector);
    }
    else {
        modal = document.createElement('div');
    }
    modal.classList.add('d-Una__checkout__modal');
    if (targetSelector)
        modal.style.borderRadius = '0';
    modal.id = 'dUnaCheckoutWidget__modal';
    return modal;
};
var removeIframe = function () {
    var modal = document.getElementById('dUnaCheckoutWidget__modal');
    var iframe = modal.querySelector('div');
    if (iframe)
        modal.removeChild(iframe);
};
export default Modal();
